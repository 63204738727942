<template>
  <div class="wrap">
    <div class="createtheme" @click="createTheme">
      <i class="el-icon-circle-plus"></i>
      创建作业主题
    </div>
    <el-table :data="tableData" border style="width: 90%">
      <el-table-column align="center" prop="id" label="序号" width="80"></el-table-column>
      <el-table-column align="center" prop="themeName" label="作业主题名称" width="180"></el-table-column>
      <el-table-column align="center" prop="themeType" label="主题类型" width="120"></el-table-column>
      <el-table-column align="center" prop="job_num" label="作业总数量" width="100"></el-table-column>
      <el-table-column align="center" prop="test_num" label="测试总数量" width="100"></el-table-column>
      <el-table-column align="center" prop="quest_num" label="问卷总数量" width="100"></el-table-column>
      <el-table-column align="center" prop="compare" label="平均提交比较" width="120"></el-table-column>
      <el-table-column align="center" prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="goworklist(scope.row.id)">详情</el-button>
          <el-button type="danger" @click="godetail(scope.row.id)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          code: 1,
          name: "主题1",
          type: "作业",
          job_num: 8,
          test_num: 6,
          quest_num: 9,
          compare: "100%"
        }
      ]
    };
  },
  methods: {
    createTheme() {
      this.$router.push({
        path: "/createTheme",
        query: {
          projectId: this.$route.params.id,
          createId: this.$route.params.createId
        }
      });
    },
    godetail(scope) {
      this.$router.push({
        path: "/createTheme",
        query: {
          id: scope, //作业主题id
          projectId: this.$route.params.id, //二级项目id
          createId: this.$route.params.createId, //主题创建者id
          edit: true //是否是点击编辑按钮进入
        }
      });
    },
    goworklist(id) {
      // console.log(id);
      this.$router.push({
        path: "/workManage",
        query: {
          themeId: id,
          createId: this.$route.params.createId
        }
      });
    },
    themeList() {
      this.$Api.Form.getThemeList(this.$route.params.id)
        .then(res => {
          res.data.map(item => {
            if (item.themeType == 1) {
              item.themeType = "作业";
            } else if (item.themeType == 2) {
              item.themeType = "测试";
            } else {
              item.themeType = "问卷";
            }
          });
          this.tableData = res.data;
        })
        .catch(err => console.log(err));
    }
  },
  mounted() {
    this.themeList();
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 100px;
  .createtheme {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px auto;
    i {
      margin-right: 10px;
    }
  }
}
</style>
